import React, { useState, useEffect } from 'react';
// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Line } from '@ant-design/charts';


const DemoLine = () => {
  const [data, setData] = useState([
    {
      "Year": "2020-01",
      "scales": 0
    },
    {
      "Year": "2020-07",
      "scales": 600
    },
    {
      "Year": "2021-01",
      "scales": 800
    },
    {
      "Year": "2021-07",
      "scales": 1000
    },
    {
      "Year": "2022-01",
      "scales": 1500
    },
    {
      "Year": "2022-07",
      "scales": 3800
    },
    {
      "Year": "2023-01",
      "scales": 7400
    },
    {
      "Year": "2023-07",
      "scales": 9600
    },
    {
      "Year": "2023-10",
      "scales": 10800
    },
    {
      "Year": "2023-12",
      "scales": 12500
    },
    {
      "Year": "2024-01",
      "scales": 15500
    },//   Anzac Project, no construction
    {
      "Year": "2024-02",
      "scales": 16500
    },//  Diamond Project, no construction
    {
      "Year": "2024-03",
      "scales": 17500
    },//  Arthur Project, no construction
    {
      "Year": "2024-04",
      "scales": 19800
    },//  Marion Project, add construction
    {
      "Year": "2024-05",
      "scales": 21800
    },//  Pooraka, Valley View, no construction
    {
      "Year": "2024-06",
      "scales": 23800
    },//  Gilles Plains construction, #5 Gepps Cross, no construction
    {
      "Year": "2024-07",
      "scales": 26600
    },//  Mobury Heights, Clearview
    {
      "Year": "2024-08",
      "scales": 27800
    },//  Marden
    {
      "Year": "2024-09",
      "scales": 28600
    },//  Christy Down
    {
      "Year": "2024-10",
      "scales": 29400
    },//  Ingle Farm
    {
      "Year": "2024-11",
      "scales": 30000
    },//  Christy Down, 3 Antony
    {
      "Year": "2024-12",
      "scales": 32100
    },//  Vista, Morphett, Raynella

  ]);

  const config = {
    data,
    padding: 'auto',
    xField: 'Year',
    yField: 'scales',
    color: '#C69852',
    point: {
      size: 5,
      shape: 'diamond',
      style: {
        fill: 'white',
        stroke: '#C69852',
        lineWidth: 2,
      },
    },
    smooth: true,
    xAxis: {
      // type: 'timeCat',
      tickCount: 5,
    },
    yAxis: {
      label: {
        formatter: (v) => {
          if (v >= 1000) {
            return `$ ${(v / 1000).toFixed(1)} M`; // Converts thousands to millions and formats it to one decimal place
          } else {
            return `$ ${v} K`;
          }
        },
      },
    },
  };

  const chartStyle = {
    width: '100%', // 宽度设置为80%，你可以根据需要调整
    height: '400px', // 高度设置为400px，同样可以按需调整
    margin: '0 auto', // 这将图表居中显示
  };

  return (
    <div className="container">
      <div style={chartStyle}>
        <Line {...config} />
      </div>
    </div>

  );
};


export default DemoLine;